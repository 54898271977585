<template>
      <base-dropdown
        ref="moveSelectedDropdown"
        direction="right"
        placement="above"
        class="relative"
      >
        <base-button slot="trigger" ref="myBtn" :disabled="disabled">
          Flyt valgte
        </base-button>

    <div slot="menu" class="p-4 w-[260px] space-y-4" @click.stop>
      <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap pb-2 rounded-md">
        <div class="ml-4 mt-4">
          <h3 class="text-base font-semibold leading-6 text-gray-900">Ændre rækkefølge</h3>
          <p class="mt-1 text-sm text-gray-500">Du er ved at flytte rækkefølgen på {{ selectedStops.length }} valgte tømninger</p>
        </div>
      </div>
      <div class="flex space-x-4 items-center">
        <div class="text-sm text-gray-900 w-24">Flyt til efter</div>
        <base-input v-model.number="row"
          type="number"
          label=""
          class="w-24"
          placeholder="Række">
        </base-input>
        <div class="text-sm w-32 text-gray-900 overflow-hidden">{{ sortedStops[row - 1] ? sortedStops[row - 1].address : 'Ingen addresse'}}</div>
      <base-button type="button" color="indigo" class="w-20 justify-end" @click="moveSelectedStops">Flyt</base-button>
      </div>
    </div>
  </base-dropdown>

</template>

<script>

import { mapState } from 'vuex';
import { UPDATE_SORTED_STOPS } from '@/store/actions.type';

export default {
  name: 'MoveSelectedStops',
  components: {
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
      required: true,
      description: 'List of active filters',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      row: null,
    };
  },
  computed: {
    ...mapState('route', ['stops', 'sortedStops', 'selectedStops']),
  },
  methods: {
    moveSelectedStops() {
      if (this.row === null || this.row < 1 || this.row > this.sortedStops.length) {
        return;
      }

      const targetIndex = this.row - 1;
      const selectedStopIds = this.selectedStops.map((stop) => stop.planned_instance_id);

      // Split sortedStops into two parts without removing selected stops yet
      const beforeStops = this.sortedStops.slice(0, targetIndex + 1);
      const afterStops = this.sortedStops.slice(targetIndex + 1);

      // Filter out the selected stops from each part
      const filteredBeforeStops = beforeStops.filter((stop) => !selectedStopIds.includes(stop.planned_instance_id));
      const filteredAfterStops = afterStops.filter((stop) => !selectedStopIds.includes(stop.planned_instance_id));

      // Insert the selected stops after the target index
      const sortedStops = [
        ...filteredBeforeStops,
        ...this.selectedStops,
        ...filteredAfterStops,
      ];

      this.$store.dispatch(`route/${ UPDATE_SORTED_STOPS}`, sortedStops);
    },
  },
};
</script>

<style>

</style>
