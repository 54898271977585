<template>
  <div class="flex flex-col double-screen-h">
    <div ref="mapContainer" class="flex map-container-height overflow-hidden relative">
      <the-route-viewer-sidebar @filter="onFilter" @toggleSidebarList="toggleSidebarListOpen"></the-route-viewer-sidebar>
      <div v-show="hasRunningJob" class="flex flex-col flex-1 map-container-height relative">
        <div class="flex m-14">
          <job-poller
              v-if="generateDurationsAndDistancesJobId"
              title="Beregner.."
              description="Dette tager typisk et par minutter. Du kan skifte til andre ruter imens"
              :job-id="generateDurationsAndDistancesJobId"
              :type="jobTypes.UPDATEDURATIONSANDDISTANCES"
              @error="onError"
              @result="onUpdatedDurationsAndDistancesResult"
              @update-related-ids="handleRelatedIds"
          ></job-poller>
        </div>
      </div>
      <div v-show="!hasRunningJob" class="flex flex-col flex-1 map-container-height relative">
        <the-leaflet-route-viewer-map
            ref="map"
            :popup-data-fetcher="getPlannedInstanceDetails"
            :add-selected-stops="addSelectedStops"
        ></the-leaflet-route-viewer-map>
        <the-route-viewer-route-stats
            :total-distance="totalDistance"
            :total-duration="totalDuration"
            :class="{ 'bottom-0': true, 'left-2': !sidebarListOpen, 'left-23': sidebarListOpen }">
        </the-route-viewer-route-stats>
        <the-route-controller
            ref="routeController"
            @zoomToPoint="onZoomToPoint"
            @updateSelectedStops="updateSelectedStops"
        ></the-route-controller>
        <the-planning-info-modal v-model="showInfoModal" @closed="onModalClose"></the-planning-info-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { GET_ROUTE, UPDATE_SELECTED_STOPS } from '@/store/actions.type';
import TheRouteViewerSidebar from '@/components/TheRouteViewerSidebar.vue';
import TheRouteViewerRouteStats from '@/components/TheRouteViewerRouteStats.vue';
import TheLeafletRouteViewerMap from '@/components/TheLeafletRouteViewerMap.vue';
import ThePlanningInfoModal from '@/components/ThePlanningInfoModal.vue';

import * as jobTypes from '@/util/jobTypes';
import TaskService from '@/services/tasks.service';
import TheRouteController from '@/components/route-viewer/route-controller/TheRouteController.vue';
import JobPoller from '@/components/JobPoller.vue';

export default {
  name: 'RouteViewerView',
  components: {
    JobPoller,
    TheLeafletRouteViewerMap,
    ThePlanningInfoModal,
    TheRouteViewerSidebar,
    TheRouteViewerRouteStats,
    TheRouteController,
  },
  data() {
    return {
      showInfoModal: false,
      sidebarListOpen: false,
      route: { stops: [], start_location: {}, disposal_point: {} },
      selectedStops: [],
      jobTypes,
      hasCompletedJob: false,
      isJobRelevant: true,
    };
  },
  computed: {
    ...mapState('route', ['stops', 'startLocation', 'disposalPoint', 'drivingListId', 'totalDistance', 'totalDuration']),
    ...mapGetters('jobs', ['generateDurationsAndDistancesJobId']),
    hasRunningJob() {
      return this.generateDurationsAndDistancesJobId && !this.hasCompletedJob && this.isJobRelevant;
    },
  },
  mounted() {
    document.querySelector('main').classList.remove('overflow-y-auto');
    window.addEventListener('markerClick', this.handleMarkerClick);
  },
  beforeDestroy() {
    window.removeEventListener('markerClick', this.handleMarkerClick);
  },
  methods: {
    async getPlannedInstanceDetails(plannedIdList) {
      return TaskService.getPopupContentData(plannedIdList);
    },
    ...mapActions('route', {
      getRoute: GET_ROUTE,
      updateSelectedStopsInStore: UPDATE_SELECTED_STOPS,
    }),
    async onUpdatedDurationsAndDistancesResult() {
      this.hasCompletedJob = true;
      await this.onFilter(this.payload);
      this.$ntf.success('Har hentet nyt rute resultat');
    },
    onError() {
      this.hasCompletedJob = true;
      this.$ntf.error('Noget gik galt i beregningen');
    },
    handleRelatedIds(related_ids) {
      // Job is only relevant if the current driving list is being calculated
      this.isJobRelevant = related_ids.includes(this.drivingListId);
    },
    async onFilter(payload) {
      this.payload = payload;

      this.$refs.map.clearFeatures();
      try {
        await this.getRoute(payload);

        if (this.$refs.map) {
          this.$refs.map.addFeatures(this.stops, this.startLocation, this.disposalLocation);
        }
      } catch (error) {
        this.$ntf.error('Noget gik galt da ruten skulle hentes');
        this.$unhandledError(error, false);
      }
    },
    onModalClose() {
      this.$refs.map.focus();
    },
    onInfoModalClick() {
      this.showInfoModal = true;
    },
    toggleSidebarListOpen() {
      this.sidebarListOpen = !this.sidebarListOpen;
      this.$refs.map.reZoom();
    },
    onZoomToPoint({ x, y, zoom }) {
      // Forward the event to the map component
      this.$refs.map.zoomToPoint({ x, y, zoom });
    },
    addSelectedStops({ newSelectedStops }) {
      const existingIds = new Set(this.selectedStops.map((stop) => stop.planned_instance_id));
      const uniqueNewStops = newSelectedStops.filter((stop) => !existingIds.has(stop.planned_instance_id));
      const selectedStops = [...this.selectedStops, ...uniqueNewStops];
      this.updateSelectedStops({ selectedStops });
    },
    updateSelectedStops({ selectedStops }) {
      this.updateSelectedStopsInStore(selectedStops);
      this.$nextTick(() => {
        if (this.$refs.map) {
          this.$refs.map.updateColorSelection(this.stops, selectedStops);
          this.$refs.map.resetMarkersZIndex(selectedStops);
        }
      });
    },
    handleMarkerClick(event) {
      const { stop } = event.detail;
      const selectedStops = [stop];
      this.updateSelectedStops({ selectedStops });
      this.$nextTick(() => {
        this.selectStop(stop);
      });
    },

    selectStop(stop) {
      if (this.$refs.routeController) {
        this.$refs.routeController.scrollToStop(stop);
      }
    },
  },
};
</script>

<style>

.double-screen-h {
  height: calc(100vh + 100vh - 125px)
}

.map-container-height {
  height: calc(100vh - 125px);
  max-height: calc(100vh - 125px);
}

</style>
