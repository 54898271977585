<template>
  <keep-alive>
    <div class="flex flex-col double-screen-h">
      <div ref="mapContainer" class="flex map-container-height overflow-hidden">
        <the-planning-sidebar @filter="onFilter"></the-planning-sidebar>

        <div class="flex flex-col flex-1 map-container-height">
          <the-leaflet-route-map
            ref="map"
            :partition-summary="partitionSummary"
            :popup-data-fetcher="getPlannedInstanceDetails"
            :info-modal-handler="onInfoModalClick"
            :share-modal-handler="onShareModalClick"
            @open-grid="openGrid"
          ></the-leaflet-route-map>

          <the-partition-summary
            :partition-summary="partitionSummary"
            :loading="plannedInstancesLoading"
          ></the-partition-summary>
        </div>

        <the-planning-info-modal v-model="showInfoModal" @closed="onModalClose"></the-planning-info-modal>
        <the-share-map-modal v-model="showShareModal" @closed="onModalClose"></the-share-map-modal>
      </div>

      <div ref="gridContainer" class="relative h-screen overflow-y-auto slim-scrollbar">
        <div class="flex flex-col px-4 mt-6 sm:px-6 lg:px-8 bg-gray-100">
          <div class="flex justify-between">
            <h2
              class="text-2xl font-extrabold text-gray-900 mb-4"
            >Visning af {{taskCount}} valgte opgaver m. {{totalQuantity}} spande</h2>

            <div class="flex space-x-8">
              <base-button color="indigo" size="xl" @click="closeTaskTable">
                <span>Tilbage til kort</span>
                <span class="bg-gray-50 text-indigo-900 rounded-md ml-4 text-xs px-2.5 py-1">ESC</span>
              </base-button>
            </div>
          </div>

          <task-table
            :selected-tasks="taskIdList"
            :hide-scroll-helpers="!isGridActive"
            :is-selection-mode="true"
            @has-updated="hasUpdated = true"
          ></task-table>
        </div>
      </div>
    </div>
  </keep-alive>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { GET_FILTERED_INSTANCES } from '@/store/actions.type';
import ThePlanningSidebar from '@/components/ThePlanningSidebar.vue';
import TheLeafletRouteMap from '@/components/TheLeafletRouteMap.vue';
import ThePlanningInfoModal from '@/components/ThePlanningInfoModal.vue';
import TheShareMapModal from '@/components/TheShareMapModal.vue';
import ThePartitionSummary from '@/components/ThePartitionSummary.vue';

import TaskTable from '@/components/table/TaskTable.vue';

import TaskService from '@/services/tasks.service';

export default {
  name: 'MapView',
  components: {
    ThePlanningSidebar,
    TheLeafletRouteMap,
    ThePlanningInfoModal,
    TheShareMapModal,
    ThePartitionSummary,
    TaskTable,
  },
  data() {
    return {
      showInfoModal: false,
      showShareModal: false,
      mapFocusTrapActive: true,
      isGridActive: false,
      gridFocusTrap: null,
      mapFocusTrap: null,
      hasUpdated: false,
      taskCount: 0,
      totalQuantity: 0,
      taskIdList: [],
      payload: {},
    };
  },
  computed: {
    ...mapGetters('planning', ['partitionSummary', 'plannedInstancesLoading']),
  },
  mounted() {
    document.querySelector('main').classList.remove('overflow-y-auto');

    document.addEventListener('keydown', this.closeTaskTableHotkey);
    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', this.closeTaskTableHotkey);
      document.querySelector('main').classList.add('overflow-y-auto');
    });
  },
  methods: {
    async getPlannedInstanceDetails(plannedIdList) {
      return TaskService.getPopupContentData(plannedIdList);
    },
    async closeTaskTableHotkey(e) {
      if (!this.isGridActive) {
        return;
      }

      // esc key
      if (e.keyCode === 27) {
        this.closeTaskTable();
      }
    },
    async closeTaskTable() {
      if (!this.isGridActive) {
        return;
      }

      setTimeout(() => {
        this.isGridActive = false;
        this.$refs.mapContainer.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }, 500);

      if (this.hasUpdated) {
        // reload the instances to the backend
        this.$ntf.info('Opdaterer kort med ændringer..');
        try {
          this.$refs.map.clearFeatures(true);
          const plannedInstances = await this.getFilteredInstances(this.payload);
          if (this.$refs.map) {
            this.$refs.map.addFeatures(plannedInstances, false);
            this.$refs.map.focusLastEditedLayer();
          }
        } catch (error) {
          this.$ntf.error('Noget gik galt da ruterne skulle hentes');
          this.$unhandledError(error, false);
        }
      } else {
        this.$refs.map.focusLastEditedLayer();
      }
    },
    ...mapActions('planning', {
      getFilteredInstances: GET_FILTERED_INSTANCES,
    }),
    openGrid({ taskIdList, count, totalQuantity }) {
      this.isGridActive = true;
      this.hasUpdated = false;
      this.taskCount = count;
      this.totalQuantity = totalQuantity;
      this.taskIdList = taskIdList;
      this.$refs.gridContainer.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    },
    onInfoModalClick() {
      this.showInfoModal = true;
    },
    onShareModalClick() {
      this.showShareModal = true;
    },
    onModalClose() {
      this.$refs.map.focus();
    },
    async onFilter(payload) {
      this.payload = payload;
      this.$refs.map.clearFeatures();
      try {
        const plannedInstances = await this.getFilteredInstances(payload);
        if (this.$refs.map) {
          this.$refs.map.addFeatures(plannedInstances);
        }
      } catch (error) {
        this.$ntf.error('Noget gik galt da ruterne skulle hentes');
        this.$unhandledError(error, false);
      }
    },
  },
};
</script>

<style>

.double-screen-h {
  height: calc(100vh + 100vh - 125px)
}

.map-container-height {
  height: calc(100vh - 125px);
  max-height: calc(100vh - 125px);
}

</style>
